<template>
	<div class="wrapper">
		<Header/>
		<main class="mainContainer">
			<slot/>
		</main>
	</div>
</template> 

<script>
import Header from '@/components/Header'

export default {
	name: 'DefaultLayout',
	components: {
		Header
	}
}
</script>

<style lang="scss">
	.mainContainer{
		padding: 44px 0;
	}
	.container{
		max-width: 1685px;
		margin: 0 auto;
		width: calc(100% - 100px);
	}

	@media screen and (max-width: 1100px){
		.mainContainer{
			padding: 15px 0 78px;
		}
		.container{
			width: calc(100% - 50px);
		}
	}
</style>