<template>
	<div id="app">
		<component :is="layout">
			<router-view/>
		</component>
	</div>
</template>

<script>
export default {
	computed: {
		layout() {
			return this.$route.meta.layout || 'default-layout'
		}
	}
}
</script>

<style lang="scss">
	@import "assets/icomoon/style.css";
</style>

<style>
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	main, menu, nav, output, ruby, section, summary,
	time, mark, audio, video{
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure,
	footer, header, hgroup, main, menu, nav, section {
		display: block;
	}

	sup{
		font-size: 13px;
		top: -4px;
		position: relative;
	}

	i{
		font-style: italic;
	}

	*{
		box-sizing: border-box;
		outline: none;
	}

	*[hidden]{
		display: none;
	}
	ol, ul{
		list-style: none;
	}
	blockquote, q{
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after{
		content: '';
		content: none;
	}
	b{
		font-weight: 700;
	}
	a{
		text-decoration: none;
		color: #000;
	}

	table{
		border-collapse: collapse;
		border-spacing: 0;
	}

	img{
		max-width: 100%;
		vertical-align: top;
	}

	body{
		min-width: 300px;
		position: relative;
		font-family: "Montserrat", sans-serif;
		-webkit-text-size-adjust: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		position: relative;
		color: #000;
	}
	input{
		font-family: "Montserrat", sans-serif;
		color: #000;
		font-size: 16px;
		line-height: 16px;
	}
	textarea{
		font-family: "Montserrat", sans-serif;
		color: #000;
		font-size: 16px;
		line-height: 16px;
		resize: none;
	}
	input::placeholder {
		color: #6C6C6C;
	}
	input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: #6C6C6C;
	}
	input::-moz-placeholder { /* Firefox 19+ */
	  color: #6C6C6C;
	}
	input:-ms-input-placeholder { /* IE 10+ */
	  color: #6C6C6C;
	}
	input:-moz-placeholder { /* Firefox 18- */
	  color: #6C6C6C;
	}
	textarea::placeholder {
		color: #6C6C6C;
	}
	textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: #6C6C6C;
	}
	textarea::-moz-placeholder { /* Firefox 19+ */
	  color: #6C6C6C;
	}
	textarea:-ms-input-placeholder { /* IE 10+ */
	  color: #6C6C6C;
	}
	textarea:-moz-placeholder { /* Firefox 18- */
	  color: #6C6C6C;
	}

	@media screen and (max-width: 1100px){
		textarea::placeholder {
			padding: 6px 0px;
		}
		textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  padding: 6px 0px;
		}
		textarea::-moz-placeholder { /* Firefox 19+ */
		  padding: 6px 0px;
		}
		textarea:-ms-input-placeholder { /* IE 10+ */
		  padding: 6px 0px;
		}
		textarea:-moz-placeholder { /* Firefox 18- */
		  padding: 6px 0px;
		}
	}
</style>